<div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
  <img class="tw-mb-2" [ngClass]="logoClass" [src]="logoSrc" [alt]="logoAlt" />
  <div class="tw-flex tw-items-center">
    <div class="tw-flex tw-items-center tw-justify-center tw-text-[#eab308] tw-text-2xl">
      <i class="bwi bwi-star-f"></i>
      <i class="bwi bwi-star-f"></i>
      <i class="bwi bwi-star-f"></i>
      <i class="bwi bwi-star-f"></i>
      <div class="tw-relative">
        <div class="tw-absolute tw-inset-0 tw-w-3 tw-overflow-hidden">
          <i class="bwi bwi-star-f"></i>
        </div>
        <i class="bwi bwi-star-f tw-text-[#cbd5e1]"></i>
      </div>
    </div>
    <span class="tw-ml-2">4.7</span>
  </div>
</div>
