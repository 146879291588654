export enum PlanType {
  Free = 0,
  FamiliesAnnually2019 = 1,
  TeamsMonthly2019 = 2,
  TeamsAnnually2019 = 3,
  EnterpriseMonthly2019 = 4,
  EnterpriseAnnually2019 = 5,
  Custom = 6,
  FamiliesAnnually = 7,
  TeamsMonthly2020 = 8,
  TeamsAnnually2020 = 9,
  EnterpriseMonthly2020 = 10,
  EnterpriseAnnually2020 = 11,
  TeamsMonthly = 12,
  TeamsAnnually = 13,
  EnterpriseMonthly = 14,
  EnterpriseAnnually = 15,
  TeamsStarter = 16,
}
