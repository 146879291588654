<!-- Please remove this disable statement when editing this file! -->
<!-- eslint-disable tailwindcss/no-custom-classname -->
<div class="tw-flex">
  <bit-form-field *ngIf="permissionMode == 'edit'" class="tw-mr-3 tw-shrink-0">
    <bit-label>{{ "permission" | i18n }}</bit-label>
    <!--
      Built-in select height differs between browsers, this fix makes sure we match bit-multi-select height.
      We might want to reconsider this fix when/if we implement
      [CL-78] [Improvement] Completely restyled selects (https://bitwarden.atlassian.net/browse/CL-78)
    -->
    <select
      class="tw-h-[35px]"
      bitInput
      [disabled]="disabled"
      [(ngModel)]="initialPermission"
      [ngModelOptions]="{ standalone: true }"
      (blur)="handleBlur()"
    >
      <option *ngFor="let p of permissionList" [value]="p.perm">
        {{ p.labelId | i18n }}
      </option>
    </select>
  </bit-form-field>

  <bit-form-field class="tw-grow">
    <bit-label>{{ selectorLabelText }}</bit-label>
    <bit-multi-select
      class="tw-w-full"
      [baseItems]="selectionList.deselectedItems"
      [removeSelectedItems]="true"
      [disabled]="disabled"
      (onItemsConfirmed)="selectItems($event)"
      (blur)="handleBlur()"
    ></bit-multi-select>
    <bit-hint *ngIf="selectorHelpText">{{ selectorHelpText }}</bit-hint>
  </bit-form-field>
</div>

<bit-table [formGroup]="formGroup">
  <ng-container header>
    <tr>
      <th bitCell>{{ columnHeader }}</th>
      <th bitCell id="permissionColHeading" *ngIf="permissionMode != 'hidden'">
        <div class="tw-border tw-border-solid tw-border-transparent">
          {{ "permission" | i18n }}
        </div>
      </th>
      <th bitCell id="roleColHeading" *ngIf="showMemberRoles">{{ "role" | i18n }}</th>
      <th bitCell id="groupColHeading" *ngIf="showGroupColumn">{{ "group" | i18n }}</th>
      <th bitCell class="tw-w-20"></th>
    </tr>
  </ng-container>
  <ng-template body formArrayName="items">
    <tr
      bitRow
      *ngFor="let item of selectionList.selectedItems; let i = index"
      [formGroupName]="i"
      [ngClass]="{ 'tw-text-muted': item.readonly }"
    >
      <td bitCell [ngSwitch]="item.type">
        <div class="tw-flex tw-items-center" *ngSwitchCase="itemType.Member">
          <bit-avatar size="small" class="tw-mr-3" text="{{ item.labelName }}"></bit-avatar>
          <div class="tw-flex tw-flex-col">
            <div>
              {{ item.labelName }}
              <span *ngIf="$any(item).status == 0" bitBadge variant="secondary">
                {{ "invited" | i18n }}
              </span>
            </div>
            <div class="tw-text-xs tw-text-muted" *ngIf="$any(item).status != 0">
              {{ $any(item).email }}
            </div>
          </div>
        </div>

        <div class="tw-flex tw-items-center" *ngSwitchDefault>
          <i
            class="bwi tw-mr-3 tw-px-0.5 tw-text-2xl"
            [ngClass]="item.icon || itemIcon(item)"
            aria-hidden="true"
          ></i>
          <span>{{ item.labelName }}</span>
        </div>
      </td>

      <td bitCell *ngIf="permissionMode != 'hidden'">
        <ng-container *ngIf="canEditItemPermission(item); else readOnlyPerm">
          <label class="sr-only" [for]="'permission' + i"
            >{{ item.labelName }} {{ "permission" | i18n }}</label
          >
          <div class="tw-relative tw-inline-block">
            <select
              bitInput
              class="tw-apperance-none -tw-ml-3 tw-max-w-40 tw-appearance-none tw-overflow-ellipsis !tw-rounded tw-border-transparent !tw-bg-transparent tw-pr-6 tw-font-bold hover:tw-border-primary-700"
              formControlName="permission"
              [id]="'permission' + i"
              (blur)="handleBlur()"
            >
              <option *ngFor="let p of permissionList" [value]="p.perm">
                {{ p.labelId | i18n }}
              </option>
            </select>
            <label
              [for]="'permission' + i"
              class="tw-absolute tw-inset-y-0 tw-right-4 tw-mb-0 tw-flex tw-items-center"
            >
              <i class="bwi bwi-sm bwi-angle-down tw-leading-[0]"></i>
            </label>
          </div>
        </ng-container>

        <ng-template #readOnlyPerm>
          <div
            *ngIf="item.accessAllItems"
            class="tw-max-w-40 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap tw-border tw-border-solid tw-border-transparent tw-font-bold tw-text-muted"
            [appA11yTitle]="accessAllLabelId(item) | i18n"
          >
            {{ "canEdit" | i18n }}
            <i class="bwi bwi-filter tw-ml-1" aria-hidden="true"></i>
          </div>

          <div
            *ngIf="item.readonly"
            class="tw-max-w-40 tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap tw-font-bold tw-text-muted"
            [title]="permissionLabelId(item.readonlyPermission) | i18n"
          >
            {{ permissionLabelId(item.readonlyPermission) | i18n }}
          </div>
        </ng-template>
      </td>

      <td bitCell *ngIf="showMemberRoles">
        {{ $any(item).role | userType: "-" }}
      </td>

      <td bitCell *ngIf="showGroupColumn">
        {{ $any(item).viaGroupName ?? "-" }}
      </td>

      <td bitCell class="tw-text-right">
        <button
          *ngIf="!item.readonly"
          type="button"
          bitIconButton="bwi-close"
          buttonType="muted"
          appA11yTitle="{{ 'remove' | i18n }} {{ item.labelName }}"
          [disabled]="disabled"
          (click)="selectionList.deselectItem(item.id); handleBlur()"
        ></button>
      </td>
    </tr>
    <tr *ngIf="selectionList.selectedItems.length == 0">
      <td bitCell>{{ emptySelectionText }}</td>
    </tr>
  </ng-template>
</bit-table>
