<h1 class="tw-text-3xl !tw-text-alt2">The Password Manager Trusted by Millions</h1>
<div class="tw-pt-32">
  <h2 class="tw-text-2xl">Everything enterprises need out of a password manager:</h2>
</div>
<ul class="tw-mt-12 tw-flex tw-flex-col tw-gap-10 tw-text-2xl tw-text-main tw-list-none tw-pl-0">
  <li><i class="bwi bwi-lg bwi-check-circle tw-mr-4"></i>Secure password sharing</li>
  <li>
    <i class="bwi bwi-lg bwi-check-circle tw-mr-4"></i>Easy, flexible SSO and SCIM integrations
  </li>
  <li><i class="bwi bwi-lg bwi-check-circle tw-mr-4"></i>Free families plan for users</li>
  <li><i class="bwi bwi-lg bwi-check-circle tw-mr-4"></i>Quick import and migration tools</li>
  <li><i class="bwi bwi-lg bwi-check-circle tw-mr-4"></i>Simple, streamlined user experience</li>
  <li><i class="bwi bwi-lg bwi-check-circle tw-mr-4"></i>Priority support and trainers</li>
</ul>
<div class="tw-mt-28 tw-flex tw-flex-col tw-items-center tw-gap-5">
  <app-logo-cnet-5-stars></app-logo-cnet-5-stars>
  <div class="tw-flex tw-items-end tw-gap-8">
    <review-logo
      logoClass="tw-w-8"
      logoSrc="../../images/register-layout/g2-logo.svg"
      logoAlt="G2 Logo"
    ></review-logo>
    <review-logo
      logoClass="tw-w-28"
      logoSrc="../../images/register-layout/capterra-logo.svg"
      logoAlt="Capterra Logo"
    ></review-logo>
    <review-logo
      logoClass="tw-w-28"
      logoSrc="../../images/register-layout/get-app-logo.svg"
      logoAlt="Get App Logo"
    ></review-logo>
  </div>
</div>
