<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog>
    <span bitDialogTitle>{{ "enrollAccountRecovery" | i18n }}</span>
    <div bitDialogContent>
      <bit-callout type="warning">
        {{ "resetPasswordEnrollmentWarning" | i18n }}
      </bit-callout>
      <app-user-verification formControlName="verification"></app-user-verification>
    </div>
    <ng-container bitDialogFooter>
      <button bitButton buttonType="primary" bitFormButton type="submit">
        {{ "submit" | i18n }}
      </button>
      <button type="button" bitButton buttonType="secondary" bitDialogClose>
        {{ "cancel" | i18n }}
      </button>
    </ng-container>
  </bit-dialog>
</form>
