<div class="tw-rounded tw-border tw-border-solid tw-border-warning-500 tw-bg-background">
  <div class="tw-bg-warning-500 tw-px-5 tw-py-2.5 tw-font-bold tw-uppercase tw-text-contrast">
    <i class="bwi bwi-exclamation-triangle bwi-fw" aria-hidden="true"></i>
    {{ "lowKdfIterations" | i18n }}
  </div>
  <div class="tw-p-5">
    <p>{{ "updateLowKdfIterationsDesc" | i18n }}</p>
    <a
      bitButton
      buttonType="secondary"
      [block]="true"
      routerLink="/settings/security/security-keys"
    >
      {{ "updateKdfSettings" | i18n }}
    </a>
  </div>
</div>
