<!-- Please remove this disable statement when editing this file! -->
<!-- eslint-disable tailwindcss/no-custom-classname -->
<div class="modal fade" role="dialog" aria-modal="true" aria-labelledby="customizeTitle">
  <div class="modal-dialog modal-dialog-scrollable tw-w-[600px] tw-max-w-none" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="customizeTitle">{{ "customizeAvatar" | i18n }}</h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          appA11yTitle="{{ 'close' | i18n }}"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card-body text-center" *ngIf="loading">
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          {{ "loading" | i18n }}
        </div>
        <app-callout type="error" *ngIf="error">
          {{ error }}
        </app-callout>
        <p class="tw-text-lg">{{ "pickAnAvatarColor" | i18n }}</p>
        <div class="tw-flex tw-flex-wrap tw-justify-center tw-gap-8">
          <ng-container *ngFor="let c of defaultColorPalette">
            <selectable-avatar
              appStopClick
              (select)="setSelection(c.color)"
              [selected]="c.selected"
              [title]="c.name"
              text="{{ profile | userName }}"
              [color]="c.color"
              [border]="true"
            >
            </selectable-avatar>
          </ng-container>
          <span>
            <span
              [tabIndex]="0"
              (keyup.enter)="showCustomPicker()"
              (click)="showCustomPicker()"
              title="{{ 'customColor' | i18n }}"
              [ngClass]="{
                '!tw-outline-[3px] tw-outline-primary-500 hover:tw-outline-[3px] hover:tw-outline-primary-500':
                  customColorSelected
              }"
              class="tw-outline-solid tw-bg-white tw-relative tw-flex tw-h-24 tw-w-24 tw-cursor-pointer tw-place-content-center tw-content-center tw-justify-center tw-rounded-full tw-border tw-border-solid tw-border-secondary-500 tw-outline tw-outline-0 tw-outline-offset-1 hover:tw-outline-1 hover:tw-outline-primary-300 focus:tw-outline-2 focus:tw-outline-primary-500"
              [style.background-color]="customColor$ | async"
            >
              <i
                [style.color]="customTextColor$ | async"
                class="bwi bwi-pencil tw-m-auto tw-text-3xl"
              ></i>
              <input
                tabindex="-1"
                class="tw-absolute tw-bottom-0 tw-right-0 tw-h-px tw-w-px tw-border-none tw-bg-transparent tw-opacity-0"
                #colorPicker
                type="color"
                [ngModel]="customColor$ | async"
                (ngModelChange)="customColor$.next($event)"
              />
            </span>
          </span>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="submit"
          class="btn btn-primary btn-submit"
          [disabled]="loading"
          (click)="submit()"
        >
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ "save" | i18n }}</span>
        </button>
        <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">
          {{ "close" | i18n }}
        </button>
      </div>
    </div>
  </div>
</div>
